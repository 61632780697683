.container{
  height: auto;
  width: 100vw;
  max-width: 800px;
  margin: 0 auto 27px auto;
}
.instructions{
  width: 93%;
  height: 140px;
  background-color: #edfcff;
  border-radius: 0 0 16px 16px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marginInstructionsFront{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 90%;
}


.marginInstructions{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  height: 90%;
}

.instructions p{
  font-size: 12px;
  margin-left: 18px;
}

.webCamContainer{
  margin-top: 8px;
  position: relative;
}

.boxes{
  display: flex;
  flex-wrap: wrap;
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  justify-content: space-between;
}

.box{
  width: 32.7%;
  height: 32.7%;
  border: #f1f3f2 1px solid;
  background-color: transparent;
}

.sample{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sampleImage{
  width: 60%;
}

.silhouette{
  margin: 10px 0 0 10px;
  width: 60%;
}

.capturePreviews{
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.previewContainer{
  position: relative;
}

.checkContainer{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview{
  width: 68px;
  height: 68px;
  background-color: #555555;
  border-radius: 8px;
}

.previewActive{
  width: 68px;
  height: 68px;
  background-color: #555555;
  border-radius: 8px;
  box-shadow: inset 0 0 0 6px #0f52f9
}

.previewImage{
  width: 68px;
  height: 68px;
  border-radius: 8px;
  -webkit-background-size: cover; /* For WebKit*/
  -moz-background-size: cover;    /* Mozilla*/
  -o-background-size: cover;      /* Opera*/
  background-size: cover;
  background-position: center;
}

.previewImageActive{
  width: 68px;
  height: 68px;
  border-radius: 8px;
  border: 2px solid #0f52f9;
  -webkit-background-size: cover; /* For WebKit*/
  -moz-background-size: cover;    /* Mozilla*/
  -o-background-size: cover;      /* Opera*/
  background-size: cover;
  background-position: center;
}

.actions{
  display: flex;
  justify-content: space-around;
}

.capture{
  background-color: #a7a7a7;
  border: #888888 5px solid;
  border-radius: 30px;
  width: 43px;
  height: 43px;
  cursor: pointer;
}

.textIcon{
  font-size: 12px;
  text-align: center;
  margin: 0 auto;
}

.iconBox{
  width: 46px;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}

.screenshot{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  -webkit-background-size: cover; /* For WebKit*/
  -moz-background-size: cover;    /* Mozilla*/
  -o-background-size: cover;      /* Opera*/
  background-size: cover;
  background-position: center;
}

.input{
  visibility: hidden;
  right: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

video {
  width: 100%;
  height: 100%;
  transform: scaleX(-1);
}

canvas {
  display: none;
}

.cameraPanel {
  position: relative;
}

.cameraLoading {
  background-color: rgb(255 255 255);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 4;
  display: flex;
  align-items: stretch;
}
.iconInstruction {
  width: 20px;
  height: 20px;
}