.imageWrapper{
    position: relative;
    width: 70%;
    max-width: 300px;
    height: 200px;
    margin: 20px auto;
}

.checkContainer{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.check{
    width: 37px;
}

.image{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: #555555;
    -webkit-background-size: cover; /* For WebKit*/
    -moz-background-size: cover;    /* Mozilla*/
    -o-background-size: cover;      /* Opera*/
    background-size: cover;
    background-position: center;
}

.imageActive{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: #555555;
    border: 3px solid #1245f4;
    -webkit-background-size: cover; /* For WebKit*/
    -moz-background-size: cover;    /* Mozilla*/
    -o-background-size: cover;      /* Opera*/
    background-size: cover;
    background-position: center;
}

.ready{
    font-family: "QuincyCF Bold", serif;
    font-size: 32px;
    text-align: center;
    color: #4d4d4d;
}

.button{
    margin: 0 auto;
    width: 215px;
    height: 37px;
    border-radius: 6px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #1245f4;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textButton{
    font-family: "ModernEra Regular", serif;
    font-size: 15px;
    font-weight: 800;
    line-height: 1.2;
    color: #ffffff;
    text-transform: uppercase;
}

.again{
    cursor: pointer;
    margin: 30px auto;
    font-family: "ModernEra Regular", serif;
    font-size: 15px;
    line-height: 1.2;
    text-align: center;
    color: #4d4d4d;
    text-decoration: underline;
}
