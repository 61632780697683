.ContainerSteps {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}

.Wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 5px;
}

.ItemStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Text {
  font-family: ModernEra Regular;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  max-width: 300px;
  margin: 0
}

.Underline {
  height: 5px;
  width: 90%;
  border-radius: 10px;
  background-color: rgb(153, 157, 158);
  margin-top: 17px;
}


.Active {
  background-color: #003fff;
}


@media(max-width: 350px){
  
}

@media(min-width: 700px){
  .ContainerSteps {
    max-width: 600px;
  }

  .Text {
    font-size: 18px;
  }

}

@media(min-width: 1000px){
  .ContainerSteps {
    max-width: 800px;
  }

  .Underline {
    width: 80%;
  }

}

