body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@font-face {
  font-family: 'QuincyCF Bold';
  src: local('QuincyCF Bold'), url(./assets/fonts/QuincyCF-Bold.woff) format('woff');
}

@font-face {
  font-family: 'ModernEra Regular';
  src: local('ModernEra Regular'), url(./assets/fonts/ModernEra-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'ModernEra ExtraBold';
  src: local('ModernEra ExtraBold'), url(./assets/fonts/ModernEra-ExtraBold.woff) format('woff');
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans'), url(./assets/fonts/NotoSans-Regular.ttf) format('truetype');
}
